import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const NavBar = ({ title, onLogin, onLogout }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} >
            { title }
          </Typography>
          <Button color="inherit" onClick={ onLogin }>Login</Button>
          <Button color="inherit" onClick={ onLogout }>Logout</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default  NavBar;