
const createLoginUri = (config) => {

  return `https://${config.authDomainPrefix}.auth.${config.region}.amazoncognito.com/login` +
         `?response_type=${config.response_type}&client_id=${config.userPoolClientId}&redirect_uri=${config.login_redirect_uri}`;
  
  // return `https://${config.authDomainPrefix}.auth.${config.region}.amazoncognito.com/login` +
  //       `?response_type=${config.response_type}&client_id=${config.userPoolClientId}&redirect_uri=${config.login_redirect_uri}`;
};

const createAuthUri = (config) => {
  console.log(`Config for LoginUri is: ${config}`);
  let scope = [ config.scope1.phone ? 'phone' : '', 
                config.scope1.email  ? 'email' : '', 
                config.scope1.openid  ? 'openid' : '', 
                config.scope1.admin  ? 'admin' : '',
                config.scope1.profile  ? 'profile' : ''];
                
  scope = scope.join('%20');
  
  console.log(`Config for AuthUri is: ${config}`);
  return  `https://${config.authDomainPrefix}.auth.${config.region}.amazoncognito.com/oauth2/authorize` +
          `?identity_provider=${config.authProvider}&redirect_uri=${config.login_redirect_uri}&client_id=${config.userPoolClientId}` +
          `&scope${scope}&response_type=${config.response_type}`;
};

const createLogoutUri = (config) => {
  return `https://${config.authDomainPrefix}.auth.${config.region}.amazoncognito.com/logout` +
         `?client_id=${config.userPoolClientId}&logout_uri=${config.logout_redirect_uri}`;
};

exports.createLoginUri  = createLoginUri;
exports.createAuthUri   = createAuthUri;
exports.createLogoutUri = createLogoutUri;