const disectTokens = (tokens) => {
    let result = {};
    let accessToken = '';
    let idToken = '';
    let expiry = '';
    let tokenParts = [];
    let error = ''; 
    
    if (tokens !== undefined && tokens.length > 1){
        try{
            let jwtArray = tokens.split("&");
            
            
            //console.log(`Disecting ${tokens}`);
            
            jwtArray.map((jwt) => {
                if( jwt.includes("access_token") ) {
                    accessToken = jwt.split('=')[1];
                }
                if( jwt.includes("id_token") ) {
                    idToken = jwt.split('=')[1];
                }
                if( jwt.includes("expires_in") ) {
                    expiry = jwt.split('=')[1];
                }
            });
            
            if( accessToken.length > 1 &&
                idToken.length > 1 &&
                expiry.length > 1) {
                
                tokenParts = accessToken.split('.');
                
                const accessTokenHeader = window.atob(tokenParts[0]);
                const accessTokenBody   = window.atob(tokenParts[1]);
                
                tokenParts = idToken.split('.');
                
                const idTokenHeader = window.atob(tokenParts[0]);
                const idTokenBody   = window.atob(tokenParts[1]);
                
                
                result =  {
                    accessToken: {
                        header: JSON.parse(accessTokenHeader),
                        body: JSON.parse(accessTokenBody)
                    },
                    idToken: {
                        header: JSON.parse(idTokenHeader),
                        body: JSON.parse(idTokenBody)
                    },
                    expiry: expiry,
                    isError: false,
                    error: error
                };
            
            }
            else{
                result.isError = true;
                result.error = `No valid tokens: ${tokens}`;
                console.log(`No valid tokens: ${tokens}`);
            }
            
        } catch(error){
            result.isError = true;
            result.error = `Could not parse tokens. Token used: ${tokens}`;
            console.log(`Could not parse tokens. Token used: ${tokens}`);
        }
    }
    else{
        result.isError = true;
        if(tokens !== undefined){
            result.error = `Tokens  are undefined.`;
            console.log(`Tokens  are undefined.`);
        }
        else{
            result.error = `No tokens in URI.`;
            console.log(`No tokens in URI.`);
        }
    }
    return result;
};
export default disectTokens;