import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

const commonBoxStyles = {
  // bgcolor: 'background.paper',
  // m: 1,
  // border: 1,
  // color: 'primary.main',
  // padding: 1,
  paddingTop: 2,
  // borderRadius: 1
};

const commonTextFieldStyles = {
  marginTop: 1,
  marginBottom: 1
};

const CognitoConfig = ({ config, onConfigChange }) => {
  const [phone, setPhone] = React.useState(config.scope1.phone);
  const [email, setEmail] = React.useState(config.scope1.email);
  const [openid, setOpenid] = React.useState(config.scope1.openid);
  const [admin, setAdmin] = React.useState(config.scope1.admin);
  const [profile, setProfile] = React.useState(config.scope1.profile);
  
  const handlePhoneChecked = (e) => {
    console.log(e.target.checked);
    setPhone(e.target.checked);
    onConfigChange({...config, scope1: { ...config.scope1, phone: e.target.checked}});
  };  
  const handleEmailChecked = (e) => {
    setEmail(e.target.checked);
    onConfigChange({...config, scope1: { ...config.scope1, email: e.target.checked}});
  };  
  const handleOpenidChecked = (e) => {
    setOpenid(e.target.checked);
    onConfigChange({...config, scope1: { ...config.scope1, openid: e.target.checked}});
  };  
  const handleAdminChecked = (e) => {
    setAdmin(e.target.checked);
    onConfigChange({...config, scope1: { ...config.scope1, admin: e.target.checked}});
  };  
  const handleProfileChecked = (e) => {
    setProfile(e.target.checked);
    onConfigChange({...config, scope1: { ...config.scope1, profile: e.target.checked}});
  };
  
  //console.log(JSON.stringify(config));
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box component="form"
            sx={{...commonBoxStyles}}
            noValidate
            autoComplete="off">
                <TextField
                    sx={{...commonTextFieldStyles}}
                    fullWidth
                    className="config-input"
                    id="text-user-pool-client-id"
                    label="User Pool client Id"
                    defaultValue= { config.userPoolClientId }
                    size="small"
                    onChange={(e) => onConfigChange({...config, userPoolClientId: e.target.value})}
                />
                <TextField
                    sx={{...commonTextFieldStyles}}
                    fullWidth
                    className="config-input"
                    id="text-domain"
                    label="Region"
                    defaultValue= { config.region }
                    size="small"
                    onChange={(e) => onConfigChange({...config, region: e.target.value})}
                />
                <TextField
                    sx={{...commonTextFieldStyles}}
                    fullWidth
                    className="config-input"
                    id="text-domain"
                    label="Domain prefix"
                    defaultValue= { config.authDomainPrefix }
                    size="small"
                    onChange={(e) => onConfigChange({...config, authDomainPrefix: e.target.value})}
                />
                 <TextField
                    sx={{...commonTextFieldStyles}}
                    fullWidth
                    className="config-input"
                    id="text-region"
                    label="Login redirect"
                    defaultValue= { config.login_redirect_uri }
                    size="small"
                    onChange={(e) => onConfigChange({...config, login_redirect_uri: e.target.value})}
                /> 
                <TextField
                    sx={{...commonTextFieldStyles}}
                    fullWidth
                    className="config-input"
                    id="text-region"
                    label="Logout redirec"
                    defaultValue= { config.logout_redirect_uri }
                    size="small"
                    onChange={(e) => onConfigChange({...config, logout_redirect_uri: e.target.value})}
                /> 
                <TextField
                    sx={{...commonTextFieldStyles}}
                    fullWidth
                    className="config-input"
                    id="text-region"
                    label="Auth provider"
                    defaultValue= { config.authProvider }
                    size="small"
                    onChange={(e) => onConfigChange({...config, authProvider: e.target.value})}
                />
                <TextField
                    sx={{...commonTextFieldStyles}}
                    fullWidth
                    className="config-input"
                    id="text-region"
                    label="Response type"
                    defaultValue= { config.response_type }
                    size="small"
                    onChange={(e) => onConfigChange({...config, response_type: e.target.value})}
                />
                <fieldset className="scope">
                  <legend className="scope-legend" align="left">Scope</legend>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="phone"
                      control={<Checkbox checked={phone}/>}
                      label="phone"
                      labelPlacement="end"
                      onChange={handlePhoneChecked}
                    />
                    <FormControlLabel
                      value="email"
                      control={<Checkbox checked={email}/>}
                      label="email"
                      labelPlacement="end"
                      onChange={handleEmailChecked}
                    />
                    <FormControlLabel
                      value="openid"
                      control={<Checkbox checked={openid}/>}
                      label="OpenId"
                      labelPlacement="end"
                      onChange={handleOpenidChecked}
                    />
                    <FormControlLabel
                      value="aws.cognito.signin.user.admin"
                      control={<Checkbox checked={admin}/>}
                      label="admin"
                      labelPlacement="end"
                      onChange={handleAdminChecked}
                    />
                    <FormControlLabel
                      value="profile"
                      control={<Checkbox checked={profile}/>}
                      label="profile"
                      labelPlacement="end"
                      onChange={handleProfileChecked}
                    />
                  </FormGroup>
                </fieldset>
                
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default  CognitoConfig;